<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="dialog"
    >
      <v-card>
        <v-toolbar dark color="#00A4B3">
          <v-toolbar-title>{{ $t("navigation") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col cols="9">
                  <user-card
                    v-if="profile != null"
                    :type="userType"
                    :profile="profile"
                    @click="clickProfile()"
                  >
                  </user-card>
                </v-col>
                <v-col cols="3">
                  <button-language class="ml-md-5" />
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list class="list-menu">
          <div class="menu-icon">
            <tab />
            <topbar />
          </div>

          <v-list-item v-for="(item, index) of listMenu" :key="index">
            <v-row>
              <v-col class="icon">
                <router-link :to="item.path">
                  <div
                    v-if="item.icon.substring(0, 4) == '<svg'"
                    v-html="item.icon"
                    class="menu__icon"
                    color="#00a4b3"
                  ></div>
                  <icons
                    v-else
                    :icon="item.icon"
                    class="menu__icon"
                    color="#00a4b3"
                  />
                </router-link>
              </v-col>
              <v-col class="name">
                <router-link :to="item.path">
                  <p>{{ item.name }}</p>
                </router-link>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
        <!-- <v-list v-if="userType == 1" class="list-menu">
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col class="icon">
                  <icons icon="home" class="menu__icon" color="#00a4b3" />
                </v-col>
                <v-col class="name">
                  <p>home</p>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="list-item">
            <v-list-item-content>
              <v-row>
                <v-col class="icon" >
                  <icons icon="test" class="menu__icon" color="#00a4b3" />
                </v-col>
                <v-col class="name">
                  <p>book lab test</p>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="list-item">
            <v-list-item-content>
              <v-row>
                <v-col class="icon">
                  <icons icon="icon-promo" class="menu__icon" color="#00a4b3" />
                </v-col>
                <v-col class="name">
                  <p>promo</p>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="list-item">
            <v-list-item-content>
              <v-row>
                <v-col class="icon">
                  <icons icon="my-package" class="menu__icon" color="#00a4b3" />
                </v-col>
                <v-col class="name">
                  <p>buy package</p>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="list-item">
            <v-list-item-content>
              <v-row>
                <v-col class="icon">
                  <icons icon="marker" class="menu__icon" color="#00a4b3" />
                </v-col>
                <v-col class="name">
                  <p>virtulab near me</p>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="list-item">
            <v-list-item-content>
              <v-row>
                <v-col class="icon">
                  <icons icon="homecare" class="menu__icon" color="#00a4b3" />
                </v-col>
                <v-col class="name">
                  <p>book home care</p>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="list-item">
            <v-list-item-content>
              <v-row>
                <v-col class="icon">
                  <icons icon="billing" class="menu__icon" color="#00a4b3" />
                </v-col>
                <v-col class="name">
                  <p>lab result</p>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="list-item">
            <v-list-item-content>
              <v-row>
                <v-col class="icon">
                  <icons icon="sign-out" class="menu__icon" color="#00a4b3" />
                </v-col>
                <v-col class="name">
                  <p>sign out</p>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list> -->
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import UserCard from "@/components/v2/sidebar/UserCard.vue";
import Auth from "@/service/auth_service";
import icons from "@/components/elements/Icons.vue";
import ButtonLanguage from "@/components/v2/button/button_language.vue";
import Tab from "@/components/v2/switch_account/tab.vue";
import Topbar from "@/components/v2/topbar/index.vue";

// import ItemMenu from "@/components/v2/sidebar/item_menu.vue";
export default {
  components: {
    UserCard,
    icons,
    ButtonLanguage,
    Tab,
    Topbar,
  },
  data: function () {
    return {
      profile: null,
      userType: "",
      listMenu: null,
      dialog: this.tampilkan,
      verified: false,
    };
  },
  computed: {
    // get data profile from store
    dataProfile() {
      return this.$store.state.profileData;
    },
    dataMenu(){
      return this.$store.state.menuData;
    }
  },
  mounted() {
    this.userType = Auth.getTypeUser();
    this.setMenu();
    if (this.dataProfile) {
      this.getProfile();
    }
  },
  props: {
    tampilkan: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    tampilkan: function (new_val, old_val) {
      if (old_val !== "") {
        this.dialog = new_val;
      }
    },
    dialog: function (new_val, old_val) {
      if (old_val !== "") {
        if (!new_val) {
          this.$emit("toggleDialog", this.dialog);
        }
      }
    },
  },
  methods: {
    titleCase(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
    clickProfile() {
      if (this.userType == 1) {
        if (!this.$route.path.includes("/patient/profile"))
          this.$router.push(`/${this.$i18n.locale}/patient/profile`);
      }
    },
    toggleDialog() {
      this.dialog = false;
      this.$emit("toggleDialog", this.dialog);
    },
    getProfile() {
      try {
        let name = "";
        let email = "";
        if (this.userType == 6) {
          this.contactPrimary =
            this.dataProfile.health_facility.contacts.filter(contactGet => {
              return true == contactGet.primary;
            });
          name = this.dataProfile.health_facility.info.company_name;
          email = this.contactPrimary[0].contact_email;
          this.verified = false;
        } else if (
          this.userType == 3 ||
          this.userType == 4 ||
          this.userType == 5
        ) {
          this.contactPrimary = this.dataProfile.company.contacts.filter(
            contactGet => {
              return true == contactGet.primary;
            }
          );
          name = this.dataProfile.company.info.company_name;
          email = this.contactPrimary[0].contact_email;
          this.verified = false;
        } else if (this.userType == 2) {
          name =
            this.dataProfile.person == undefined
              ? "-"
              : this.dataProfile.person.name;
          email =
            this.dataProfile.person == undefined
              ? "-"
              : this.dataProfile.person.contacts.email;
          this.verified = false;
        } else {
          name = this.dataProfile.person.personal_info.name;
          email = this.dataProfile.person.personal_info.contacts.email;
          this.verified = this.dataProfile.person.patient.verified;
        }

        this.profile = {
          email: email,
          name: name,
          verified: this.verified,
          urlImage:
            this.dataProfile.photo_profile_path == null
              ? null
              : `${process.env.VUE_APP_API_TRUST}profile/${this.dataProfile.photo_profile_path}`,
        };
      } catch (error) {
        console.log(error);
      }
    },
    async setMenu() {
      if (this.userType == 1) {
        this.listMenu = [
          {
            name: this.$t("menu.home"),
            path: "/patient",
            icon: "home",
          },
          {
            name: this.$t("menu.book_lab_test"),
            icon: "test",
            path: "/patient/test",
          },
          {
            name: this.$t("menu.book_home_care"),
            icon: "homecare",
            path: "/patient/home-care",
          },
          // {
          //   name: this.$t("menu.my_order"),
          //   icon: "icon-booking",
          //   path: "/patient/my-order",
          // },
          {
            name: this.$t("menu.buy_package"),
            icon: "my-package",
            path: "/patient/buy-package",
          },
          //  {
          //   name: this.$t("menu.buy_subscription"),
          //   icon: "my-package",
          //   path: "/patient/buy-subscription",
          // },
          // {
          //   name: this.$t("menu.promo"),
          //   icon: "icon-promo",
          //   path: "/patient/promo",
          // },
          {
            name: this.$t("menu.virtulab_nearme"),
            icon: "marker",
            path: "/patient/nearme",
          },
          {
            name: this.$t("menu.account"),
            path: "/patient/profile",
            icon: "user",
          },
          // {
          //   name: this.$t("menu.setting"),
          //   path: "/patient/setting/password",
          //   icon: "icon-gear",
          // },
          {
            name: this.$t("menu.sign_out"),
            path: "/sign-out",
            icon: "sign-out",
          },
        ];
        // this.settingMenu = {
        //   name: "Setting",
        //   path: "/patient/setting/profile",
        //   icon: "setting",
        // };
      } else if (this.userType == 2) {
        this.listMenu = [
          {
            name: this.$t("menu.home"),
            path: "/doctor",
            icon: "home",
          },
          {
            name: this.$t("menu.patient_appointment"),
            path: "/doctor/appointment/patient-list",
            icon: "billing",
          },
          // {
          //   name: this.$t("menu.referal"),
          //   path: "/doctor/referral/confirmation",
          //   icon: "user",
          // },
          {
            name: this.$t("menu.sign_out"),
            path: "/sign-out",
            icon: "sign-out",
          },
        ];
        // this.settingMenu = {
        //   name: "Setting",
        //   path: "/doctor/setting/profile",
        //   icon: "setting",
        // };
      } else if (this.userType == 3) {
        //if(this.dataMenu)
        let newVar = [];
        this.dataMenu.forEach(element => {
          var capitalizeFinal = this.titleCase(element.name);
          newVar = [
            ...newVar,
            { name: capitalizeFinal, path: element.link, icon: element.icon },
          ];
        });

        this.listMenu = newVar;
        // this.listMenu = [
        //   {
        //     name: this.$t("menu.home"),
        //     path: "/company",
        //     icon: "home",
        //   },
        //   {
        //     name: this.$t("menu.master_employee"),
        //     path: "/company/employee/master",
        //     icon: "user-office",
        //   },
        //   {
        //     name: this.$t("menu.book_office_care"),
        //     icon: "office",
        //     path: "/company/office-care",
        //   },

        //   {
        //     name: this.$t("menu.medical_record"),
        //     icon: "billing",
        //     path: "/company/medical-record",
        //   },
        //   {
        //     name: this.$t("tab.deposit_trans"),
        //     icon: "billing",
        //     path: "/company/deposit-transaction",
        //   },
        //   {
        //     name: this.$t("menu.sign_out"),
        //     path: "/sign-out",
        //     icon: "sign-out",
        //   },
        // ];
      }
      // else if (this.userType == 4) {
      //   const resp = await API.get(`${process.env.VUE_APP_API_TRUST}get-menu`, {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${Auth.getToken()}`,
      //   });

      //   let newVar = [];
      //   resp.results.forEach((element) => {
      //     if (this.$i18n.locale == "id") {
      //       newVar = [
      //         ...newVar,
      //         { name: element.name_id, path: element.link, icon: element.icon },
      //       ];
      //     } else {
      //       newVar = [
      //         ...newVar,
      //         { name: element.name_en, path: element.link, icon: element.icon },
      //       ];
      //     }
      //   });
      //   this.listMenu = newVar;
      //   // this.listMenu = [
      //   //   {
      //   //     name: this.$t("menu.home"),
      //   //     path: "/company",
      //   //     icon: "home",
      //   //   },
      //   //   {
      //   //     name: this.$t("menu.examination_approve"),
      //   //     icon: "billing",
      //   //     path: "/company/examination-approval",
      //   //   },
      //   //   {
      //   //     name: this.$t("menu.examination_history"),
      //   //     icon: "office",
      //   //     path: "/company/office-care",
      //   //   },
      //   //   {
      //   //     name: this.$t("menu.medical_record"),
      //   //     icon: "billing",
      //   //     path: "/company/medical-record",
      //   //   },
      //   //   {
      //   //     name: this.$t("tab.deposit_trans"),
      //   //     icon: "billing",
      //   //     path: "/company/deposit-transaction",
      //   //   },
      //   //   {
      //   //     name: this.$t("menu.sign_out"),
      //   //     path: "/sign-out",
      //   //     icon: "sign-out",
      //   //   },
      //   // ];
      // }
      // else if (this.userType == 5) {
      //   const resp = await API.get(`${process.env.VUE_APP_API_TRUST}get-menu`, {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${Auth.getToken()}`,
      //   });

      //   let newVar = [];
      //   resp.results.forEach((element) => {
      //     if (this.$i18n.locale == "id") {
      //       newVar = [
      //         ...newVar,
      //         { name: element.name_id, path: element.link, icon: element.icon },
      //       ];
      //     } else {
      //       newVar = [
      //         ...newVar,
      //         { name: element.name_en, path: element.link, icon: element.icon },
      //       ];
      //     }
      //   });
      //   this.listMenu = newVar;
      //   // this.listMenu = [
      //   //   {
      //   //     name: this.$t("menu.home"),
      //   //     path: "/company",
      //   //     icon: "home",
      //   //   },
      //   //   {
      //   //     name: this.$t("menu.examination_approve"),
      //   //     icon: "billing",
      //   //     path: "/company/examination-approval",
      //   //   },
      //   //   {
      //   //     name: this.$t("menu.examination_history"),
      //   //     icon: "office",
      //   //     path: "/company/office-care",
      //   //   },
      //   //   {
      //   //     name: this.$t("menu.medical_record"),
      //   //     icon: "billing",
      //   //     path: "/company/medical-record",
      //   //   },
      //   //   {
      //   //     name: this.$t("tab.deposit_trans"),
      //   //     icon: "billing",
      //   //     path: "/company/deposit-transaction",
      //   //   },
      //   //   {
      //   //     name: this.$t("menu.sign_out"),
      //   //     path: "/sign-out",
      //   //     icon: "sign-out",
      //   //   },
      //   // ];
      // }
      else if (this.userType == 6) {
        this.listMenu = [
          {
            name: this.$t("menu.home"),
            path: "/medical-center",
            icon: "home",
          },

          {
            name: this.$t("menu.medical_record"),
            path: "/medical-center/mr-patient",
            icon: "billing",
          },
          // {
          //   name: "Summary Reports",
          //   path: "/doctor/summary-report/referral",
          //   icon: "summary-reports",
          // },
          // {
          //   name: this.$t("menu.referal"),
          //   path: "/doctor/referral/confirmation",
          //   icon: "user",
          // },
          {
            name: this.$t("menu.sign_out"),
            path: "/sign-out",
            icon: "sign-out",
          },
        ];
        // this.settingMenu = {
        //   name: "Setting",
        //   path: "/doctor/setting/profile",
        //   icon: "setting",
        // };
      } else {
        this.listMenu = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.name {
  margin-left: -80%;
  font-size: 14px;
  margin-top: 2px;
  color: #00a4b3;
  p {
    text-transform: capitalize;
  }
}

.list-menu {
  margin-left: 15px;
}

.list-item {
  margin-top: -10px;
}

.dialog {
  z-index: 1000;
}

a {
  color: #00a4b3;
  text-decoration: none;
}
.menu-icon {
  padding: 10px 12px 12px 5px;
}
</style>

<template>
  <v-card
    class="card-top pa-3 pa-md-5"
    :class="{ 'card-top--hidden': !showNavbar }"
  >
    <!-- <section class="pa-4 pt-5"> -->
    <v-row dense>
      <v-col cols="8" md="9" lg="9" class="d-flex align-center">
        <div class="d-none d-md-flex flex-grow-1 align-center">
          <h4 class="role">{{ $t("role_as") }}:</h4>
          <tab />
        </div>
        <topbar />
        <div
          @click="() => $router.go(-1)"
          class="d-flex align-center mr-1 mr-md-3"
        >
          <icons
            icon="arrow-back"
            color="#00A4B3"
            class="arrow-back d-flex align-center ml-n2 ml-md-n3"
          />
        </div>
        <v-autocomplete
          v-model="payload.serviceName"
          :items="listService"
          item-value="id"
          :item-text="$i18n.locale == 'id' ? `name` : `name_${$i18n.locale}`"
          class="inp-text service-search"
          rounded
          dense
          prepend-icon="mdi-magnify"
          append-icon=""
          default=""
          ref="serviceName"
          :placeholder="$t('page.nearme.search_nearme')"
          :class="{
            'd-none': !showSearch,
          }"
        >
          <!-- <template v-slot:selection="{ item }">
              <localized-link
                :to="`/patient/test/detail?branch=default&product=${item.id}`"
              >
                {{item.name}}
              </localized-link>
          </template> -->
        </v-autocomplete>
        <!--
        <text-field
          class="text-field my-auto"
          iconRight="search"
          theme="search"
          rounded
          shadow
          :placeholder="$t('page.nearme.search_nearme')"
          :class="{
            'd-none': !showSearch,
          }"
        />
        -->
        <img
          src="/iconVirtu.png"
          alt="Icon Virtu"
          class=""
          style="width: 100px"
          :class="{
            'd-none': !showLogo,
          }"
        />
      </v-col>
      <v-col cols="4" md="3">

        <div class="d-flex justify-end align-center item-center" style="height: 100%">
          <button-language class="d-none d-md-flex ml-5" />
          <notification class="d-none d-md-flex " />

          <Cart
            :class="{
              'd-none': !showCart,
              'd-md-flex': showCartDesktop,
              'd-md-none': !showCartDesktop,
            }"
            class="ml-3 mr-3 mr-md-0"
          />
          <div
            @click="openDialogNotif()"
            class="d-block d-md-none align-center icons"
          >
          <v-badge
            color="pink"
            :content="$store.state.notifLength"
            :value="$store.state.notifLength"
            class="badge"
            v-if="$store.state.notifLength != ''"
          >
          <icons icon="bell" color="#00A4B3" class="icon d-md-none d-lg-none mt-1"/>
          </v-badge>
          <icons icon="bell" color="#00A4B3" class="icon d-md-none d-lg-none mt-1" v-else/>
            <!-- <icons
              icon="bell"
              color="#00a4b3"
              class="icon d-md-none d-lg-none mt-1"
            /> -->
          </div>
          <user-card
            type="patient"
            class="pa-0 user-card-custom nav d-flex d-md-none ml-3 width-60"
            :hideDetail="true"
            @click="openDialog()"
          >
          </user-card>
        </div>
      </v-col>
    </v-row>
    <DialogMenu
      :tampilkan="dataDialog"
      @toggleDialog="(dialog) => toggleDialogMenu(dialog)"
    />
    <DialogNotification
      :tampilkan="dataDialogNotif"
      @toggleDialog="(dialogNotif) => toggleDialogNotification(dialogNotif)"
    />
    <!-- </section> -->
  </v-card>
</template>

<script>
//import TextField from "@/components/v2/input/text_field.vue";
import Cart from "@/components/headers/Cart.vue";
import DialogMenu from "@/components/v2/dialog/dialogMenu.vue";
import DialogNotification from "@/components/v2/dialog/dialogNotification.vue";
import notification from "@/components/v2/notification/index.vue";
import UserCard from "@/components/v2/sidebar/UserCard.vue";
import icons from "@/components/elements/Icons.vue";
import Auth from "@/service/auth_service";
import API from "@/service/api_service.js";
import ButtonLanguage from "@/components/v2/button/button_language.vue";
import Tab from "@/components/v2/switch_account/tab.vue";
import Topbar from "@/components/v2/topbar/index.vue";

export default {
  components: {
  //  TextField,
    Cart,
    DialogMenu,
    DialogNotification,
    notification,
    UserCard,
    icons,
    ButtonLanguage,
    Tab,
    Topbar,
  },
  props: {
    showNavbar: {
      type: Boolean,
      default: true,
    },
    showCart: {
      type: Boolean,
      default: false,
    },
    showCartDesktop: {
      type: Boolean,
      default: false,
    },
    showSearch: {
      type: Boolean,
      default: false,
    },
    showLogo: {
      type: Boolean,
      default: false,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
    dialogNotif: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dataDialog: false,
    dataDialogNotif: false,
    dialogSearch: false,
    payload: {
      serviceName: [],
    },
    listService: [],
    isUpdating:false,
    productId:'',
  }),
  watch: {
    "payload.serviceName": function (itemId) {
      if(itemId != ''){
        this.$router.push(`/${this.$i18n.locale}/patient/test/${itemId}?branch=`);
      }
    },
  },
  created() {
    this.dataDialog = this.dialog;
    this.dataDialogNotif = this.dialogNotif;
    this.getProduct();
  },
  methods: {
    async getProduct() {
       try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}product/get`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode === 200) {
          this.listService = resp.results;
        }
      } catch (error) {
        console.log(error);
      }  
    },
    openDialog() {
      this.dataDialog = true;
    },
    openDialogNotif() {
      this.dataDialogNotif = true;
    },
    toggleDialogMenu(toggle) {
      this.dataDialog = toggle;
    },
    toggleDialogNotification(toggle) {
      this.dataDialogNotif = toggle;
    },
  },
};
</script>
<style lang="scss">
.v-badge{
  line-height: 2 !important;
}
.card-top {
  .user-card-custom {
    // justify-content: flex-end;
    width: auto !important;
    .user-card__avatar {
      width: 40px;
      height: 40px;
    }
  }
}
@media screen and (max-width: 960px) {
  .icons .v-badge__badge{
    border-radius: 130px !important;
    font-size: 8px !important;
    height: 17px !important;
    min-width: 18px !important;
    inset: auto auto calc(85% - 4px) calc(85% - 4px) !important;
  }

  .icons > .icon{
    padding-bottom: 3px !important;
  }
  .inp-text {
    margin-top:0 !important;
    margin-bottom: 5px !important;
  }
}
</style>
<style lang="scss" scoped>
@import "../../../assets/scss/color.scss";
.inp-text {
  background: #e1e8f5;
  border-radius: 10px;
  height: 40px;
  padding: 4px;
  font-size: 12px !important;
}
.bg-blue{
  background: #e1e8f5;
}
.arrow-back {
  cursor: pointer;
}

.home {
  display: flex;
  flex-direction: column;
  padding: 1px 0 40px 0;
}
.tab-switch {
  color: $main_2;
  font-size: 14px;
  margin-right: 8px;
}

//aulio sementara nambah
.menu {
  margin-top: 12px;
}
.title-section {
  color: #1f3c87;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 0px;
  margin-left: 8px;
}
.see-all {
  font-size: 14px;
  color: #00a4b3;
  margin-bottom: 0px;
}
.card-slider {
  margin-top: 16px;
  //padding: 0px 8px;
}
.feel-card {
  padding: 12px 0px;
}
.card-feel-margin {
  margin: 5px 10px 5px 2px;
}
.boxCard {
  position: relative;
  background: #ffffff;
  border-radius: 10px !important;
  padding: 10px;
  overflow: hidden;
  height: 100%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}
.items-title {
  font-size: 14px;
  color: #00a4b3;
  font-weight: 600;
  margin-bottom: 0px;
}
a {
  text-decoration: none;
}
.box-row {
  padding: 12px 0px 12px 0px;
}
.card-popular {
  padding: 10px 10px;
  border-radius: 10px;
  background: #ffffff;
  margin-top: 5px;
  height: 100%;
  //border: 1px solid #777777;
  border: thin solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  //filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.25));
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}
.popular-title {
  font-size: 13px;
  color: #1f3c87;
  font-weight: 600;
  margin-bottom: 5px;
}
.popular-detail {
  color: #00a4b3;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0px;
}
.popular-action {
  text-align: right;
}
.popular-price {
  margin-bottom: 5px;
  font-size: 13px;
  color: #1f3c87;
  font-weight: 450;
}
.btn-detail {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background-color: #00a4b3 !important;
  color: #fff;
  font-size: 12px;
  text-transform: capitalize;
  height: 20px;
}

.jargon-title {
  font-size: 18px;
  font-weight: bold;
  color: #1f3c87;
  margin-left: 12px;
}

.jargon-desc {
  font-size: 16px;
  color: #00a4b3;
  margin-bottom: 9px;
  margin-left: 12px;
}

.v-application p {
  margin-bottom: 9px;
}

.text-field {
  max-width: 70%;
}

// .col-2 {
//   flex: 0 0 16.6666666667%;
//   max-width: 10% !important;
// }
// .card-top {
//   height: 60px;
// }

@media screen and (max-width: 960px) {
  .jargon-title {
    font-size: 10px;
    margin-left: 46px;
  }
  .jargon-desc {
    font-size: 8px;
    margin-left: 46px;
  }
  .column-icon {
    margin-left: -13px !important;
  }
  .simple-profile {
    width: 73px !important;
    height: 60px !important;
  }

  .second-column {
    margin-left: 2px !important ;
  }

  .btn-detail {
    width: 100%;
  }

  .text-field {
    max-width: 85%;
    margin-left: 0px !important;
  }

  .popular-action {
    margin-top: -25px;
    text-align: center;
  }

  .card-top {
    position: fixed;
    top: 0;
    z-index: 999;
    transition: 0.3s all ease-in-out;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }

  .card-top.card-top--hidden {
    transform: translate3d(0, -150%, 0);
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }

  @-webkit-keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    100% {
      opacity: 1;
      -webkit-transform: none;
      transform: none;
    }
  }
}
//aulio sementara nambah end
// stylefilterTab
.header {
  margin-bottom: 11px;
  margin-top: 2px;
}

.tagline {
  font-size: 20px;
  font-weight: bold;
  color: #1f3c87;
}

.description {
  font-size: 15px;
  color: black;
  font-weight: 200;
  margin-top: 10px;
}

.card {
  border-radius: 10px;
  width: 100%;
  height: 50%;
}

.branch {
  margin-left: -17px;
}

.service-filter {
  margin-top: 5px;
  padding: 0;
}

.date-filter {
  margin-top: 5px;
}

.mx-auto {
  margin-top: 25px;
}

.filter-row-home-patient {
  margin-top: 5px;
  font-size: 12px;
}

.filterTab {
  text-transform: capitalize;
  color: #1f3c87;
  font-weight: bold;
  padding-top: 0px;
  margin-top: 0px;
  font-size: 12px;
}

.button {
  font-size: 12px;
  text-align: center;
  width: 100%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  background-color: #00a4b3 !important;
  font-size: 12px;
  color: #fff;
  text-transform: capitalize;
  height: 25px;
}
.theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px !important;
}
.v-label {
  font-size: 12px;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 130px;
  padding: 0 16px;
}

.v-card__actions {
  align-items: center;
  display: flex;
  padding: 8px;
  margin-bottom: 10px;
  margin-top: -30px;
}

.v-select__selections {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  line-height: 18px;
  max-width: 100%;
  min-width: 0;
  font-size: 12px;
  font-weight: 200;
}

.v-autocomplete.v-select.v-input input {
  min-width: 64px;
  font-weight: 200;
  font-size: 12px;
}

// .cart {
//   margin-left: 380px;
// }

@media screen and (max-width: 960px) {
  .mx-auto {
    margin-top: 10px;
  }
  .service-filter {
    margin-top: -20px;
  }

  .date-filter {
    margin-top: -15px;
  }

  .cart {
    margin-left: 17px;
    margin-right: -14px;
  }

  .bell {
    margin-left: 8px;
  }

  .user-card .user-card__info {
    display: flex;
  }

  .slider-promo {
    display: none;
  }

  .card-top {
    position: fixed;
    width: 100%;
    display: block;
    transition: top 0.3s;
    left: 0;
    right: 0;
  }

  .item-center{
    align-items: flex-end !important;
  }
  .item-center > .cart {
    display: inline-block !important;
  }
}

.filter-row-home-patient .theme--light.v-icon {
  color: #1f3c87;
}

.filter-row-home-patient .v-label {
  font-size: 12px;
  line-height: 1;
  min-height: 2px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.filter-row-home-patient .theme--light.v-label {
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  text-transform: capitalize;
}

.filter-row-home-patient .v-select__selections {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  line-height: 18px;
  max-width: 100%;
  min-width: 0;
  font-size: 12px;
  text-transform: capitalize;
  font-weight: 200;
}
.filter-row-home-patient .v-text-field input {
  font-size: 12px;
}
.filter-row-home-patient
  .v-autocomplete.v-input
  > .v-input__control
  > .v-input__slot {
  font-size: 12px;
  font-weight: 200;
}
.date {
  text-transform: uppercase;
  font-size: 12px;
}
.margin-btn-detail {
  margin-top: 25px;
}
// endstylefilterTab
// .row--dense {
//   margin: 10px;
// }
.padding-box {
  padding: 10px;
}
//service
.v-select__selections {
  align-items: center;
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  line-height: 18px;
  max-width: 100%;
  min-width: 0;
  font-size: 12px;
  text-transform: capitalize;
}

.content {
  color: #1f3c87;
  font-weight: bold;
  font-size: 12px;
}
.service {
  color: rgba(0, 0, 0, 0.6);
  text-transform: capitalize;
  font-size: 12px;
}
//EndService
.cart-right {
  text-align: right;
  justify-content: flex-end;
  background: none;
  width: 95%;
  margin-top: -19px;
}
.section {
  padding: 20px;
}

</style>

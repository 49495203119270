<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="dialog"
    >
      <v-card>
        <v-toolbar dark color="#00A4B3">
          <v-toolbar-title>{{ $t("notification") }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
        <v-row
          class="ma-4"
          dense
          v-if="this.$store.getters.getNotification.length > 0"
        >
          <v-col
            cols="12 mb-2"
            v-for="(item, index) of this.$store.getters.getNotification"
            :key="index"
            :class="{ unread: !item.is_read }"
          >
            <v-card color="#FFF" class="inside">
              <router-link
                style="text-decoration: none; color: inherit"
                to="/company/medical-record"
                @click.native="
                  readNotificationCompany(item.notification_id, item.type)
                "
                v-if="userType == 3"
              >
                <v-row class="" dense>
                  <v-col cols="1">
                    <v-card-title class="text-h6 pb-0 pt-3 text-title">
                      <icons
                        icon="bell"
                        class="menu__icon float-right"
                        color="#000"
                        style="margin-left: 140%"
                      />
                    </v-card-title>
                  </v-col>
                  <v-col cols="11">
                    <v-card-title class="text-h6 pb-0 pt-3 text-title mb-2">
                      {{
                        item[`title_${$i18n.locale}`] == null
                          ? item[`title_en`]
                          : item[`title_${$i18n.locale}`]
                      }}
                    </v-card-title>
                    <v-card-text class="pb-0 mb-0 text-card">
                      {{
                        item[`body_${$i18n.locale}`] == null
                          ? item[`body_en`]
                          : item[`body_${$i18n.locale}`]
                      }}
                    </v-card-text>
                    <v-card-subtitle class="pt-2 mt-0 text-caption">
                      <span class="grey--text">{{
                        formatDate(item.created_at)
                      }}</span>
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </router-link>

              <router-link
                style="text-decoration: none; color: inherit"
                to="/patient/profile?tab=order"
                @click.native="
                  readNotification(item.notification_id, item.type)
                "
                v-else
              >
                <v-row class="" dense>
                  <v-col cols="1">
                    <v-card-title class="text-h6 pb-0 pt-3 text-title">
                      <icons
                        icon="bell"
                        class="menu__icon float-right"
                        color="#000"
                        style="margin-left: 140%"
                      />
                    </v-card-title>
                  </v-col>
                  <v-col cols="11">
                    <v-card-title class="text-h6 pb-0 pt-3 text-title mb-2">
                      {{
                        item[`title_${$i18n.locale}`] == null
                          ? item[`title_en`]
                          : item[`title_${$i18n.locale}`]
                      }}
                    </v-card-title>
                    <v-card-text class="pb-0 mb-0 text-card">
                      {{
                        item[`body_${$i18n.locale}`] == null
                          ? item[`body_en`]
                          : item[`body_${$i18n.locale}`]
                      }}
                    </v-card-text>
                    <v-card-subtitle class="pt-2 mt-0 text-caption">
                      <span class="grey--text">{{
                        formatDate(item.created_at)
                      }}</span>
                    </v-card-subtitle>
                  </v-col>
                </v-row>
              </router-link>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
// import UserCard from "@/components/v2/sidebar/UserCard.vue";
import Auth from "@/service/auth_service";
import API from "@/service/api_service";
import icons from "@/components/elements/Icons.vue";
import moment from "moment";
// import ItemMenu from "@/components/v2/sidebar/item_menu.vue";
export default {
  components: {
    // UserCard,
    icons,
  },

  data: function () {
    return {
      hitung: ["0", "1", "2", "3", "4", "5"],
      profile: null,
      userType: "",
      listMenu: null,
      dialog: this.tampilkan,
      notification_list: [],
    };
  },
  computed: {
    // get data profile from store
    dataProfile() {
      return this.$store.state.profileData;
    },
    dataMenu(){
      return this.$store.state.menuData;
    }
  },

  mounted() {
    this.userType = Auth.getTypeUser();
    this.setMenu();
    this.getNotification();
    if (this.dataProfile) {
      this.getProfile();
    }
  },
  props: {
    tampilkan: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    tampilkan: function (new_val, old_val) {
      if (old_val !== "") {
        this.dialog = new_val;
      }
    },
    dialog: function (new_val, old_val) {
      if (old_val !== "") {
        if (!new_val) {
          this.$emit("toggleDialog", this.dialog);
        }
      }
    },
  },
  methods: {
    titleCase(str) {
      var splitStr = str.toLowerCase().split(" ");
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
    async readNotificationCompany(notif_id, type) {
      var form = {
        notification_id: [notif_id],
      };

      let resp = await API.post(
        `${process.env.VUE_APP_API_TRUST}notifications/mark-as-read`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        form
      );
      if (resp) {
        if (type.includes("SHARING")) {
          this.$router
            .push(`/${this.$i18n.locale}/company/medical-record?tab=SHARE`)
            .catch(() => {});
         
        } else {
          this.$router
            .push(`/${this.$i18n.locale}/company/medical-record?tab=COMPANY`)
            .catch(() => {});
         
        }
      }
      this.$store.dispatch("fetchNotification");
     
    },
    async readNotification(notif_id, type) {
      var form = {
        notification_id: [notif_id],
      };

      let resp = await API.post(
        `${process.env.VUE_APP_API_TRUST}notifications/mark-as-read`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        form
      );
      // if (resp) {
      //   this.$router.push("/patient/profile?tab=order").catch(() => {});
      // } else {
      //   this.$router.push("/patient/profile?tab=order").catch(() => {});
      // }
      if (resp) {
        if (type.includes("FAMILY")) {
          this.$router
            .push(`/${this.$i18n.locale}/patient/profile?tab=${type}`)
            .catch(() => {});
          //this.$store.commit("setActiveTab", 3);
        } else {
          this.$router
            .push(`/${this.$i18n.locale}/patient/profile?tab=${type}`)
            .catch(() => {});
          //this.$store.commit("setActiveTab", 6);
        }
      }
      this.$store.dispatch("fetchNotification");
    },
    formatDate(date) {
      return moment(date)
        .locale(this.$i18n.locale)
        .format("DD-MMM-YYYY HH:mm:ss")
        .toUpperCase();
    },
    async getNotification() {
      let resp = await API.get(
        `${process.env.VUE_APP_API_TRUST}notifications/get?per_page=10&page=1`,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        }
      );
      this.notification_list = resp.results;
    },
    toggleDialog() {
      this.dialog = false;
      this.$emit("toggleDialog", this.dialog);
    },
    async getProfile() {
      try {
        let name = "";
        let email = "";
        if (this.userType == 6) {
          this.contactPrimary =
            await this.dataProfile.health_facility.contacts.filter(
              contactGet => {
                return true == contactGet.primary;
              }
            );
          name = this.dataProfile.health_facility.info.company_name;
          email = this.contactPrimary[0].contact_email;
          this.verified = false;
        } else if (
          this.userType == 3 ||
          this.userType == 4 ||
          this.userType == 5
        ) {
          this.contactPrimary = await this.dataProfile.company.contacts.filter(
            contactGet => {
              return true == contactGet.primary;
            }
          );
          name = this.dataProfile.company.info.company_name;
          email = this.contactPrimary[0].contact_email;
        } else if (this.userType == 2) {
          name =
            this.dataProfile.person == undefined
              ? "-"
              : this.dataProfile.person.name;
          email =
            this.dataProfile.person == undefined
              ? "-"
              : this.dataProfile.person.contacts.email;
        } else {
          name = this.dataProfile.person.personal_info.name;
          email = this.dataProfile.person.personal_info.contacts.email;
        }

        this.profile = {
          email: email,
          name: name,
          urlImage:
            this.dataProfile.photo_profile_path == null
              ? null
              : `${process.env.VUE_APP_API_TRUST}profile/${this.dataProfile.photo_profile_path}`,
        };
      } catch (error) {
        console.log(error);
      }
    },
    async setMenu() {
      if (this.userType == 1) {
        this.listMenu = [
          {
            name: "Home",
            path: "/patient",
            icon: "home",
          },
          {
            name: "Profile",
            path: "/patient/profile",
            icon: "user-filled",
          },
          {
            name: "Book Lab Test",
            icon: "test",
            path: "/patient/test",
          },
          // {
          //   name: "Promo",
          //   icon: "icon-promo",
          //   path: "/patient/promo",
          // },
          {
            name: "Buy Package",
            icon: "my-package",
            path: "/patient/buy-package",
          },
          {
            name: "Virtu DigiLab Near Me",
            icon: "marker",
            path: "/patient/nearme",
          },
          {
            name: "Book Home Care",
            icon: "homecare",
            path: "/patient/home-care",
          },
          {
            name: "Lab Result",
            icon: "billing",
            path: "/patient/lab-result",
          },
          {
            name: "Sign Out",
            path: "/sign-out",
            icon: "sign-out",
          },
        ];
        // this.settingMenu = {
        //   name: "Setting",
        //   path: "/patient/setting/profile",
        //   icon: "setting",
        // };
      } else if (this.userType == 2) {
        this.listMenu = [
          {
            name: "Home",
            path: "/doctor",
            icon: "home",
          },
          // {
          //   name: "Summary Reports",
          //   path: "/doctor/summary-report/referral",
          //   icon: "summary-reports",
          // },
          // {
          //   name: "Referral",
          //   path: "/doctor/referral/confirmation",
          //   icon: "user-plus",
          // },
          {
            name: "Sign Out",
            path: "/sign-out",
            icon: "sign-out",
          },
        ];
        // this.settingMenu = {
        //   name: "Setting",
        //   path: "/doctor/setting/profile",
        //   icon: "setting",
        // };
      } else if (this.userType == 3) {
        let newVar = [];
        this.dataMenu.forEach(element => {
          var capitalizeFinal = this.titleCase(element.name);
          newVar = [
            ...newVar,
            { name: capitalizeFinal, path: element.link, icon: element.icon },
          ];
        });

        this.listMenu = newVar;
        // this.listMenu = [
        //   {
        //     name: "Home",
        //     path: "/company",
        //     icon: "home",
        //   },
        //   {
        //     name: "Master Employee",
        //     path: "/company/employee/master",
        //     icon: "user-office",
        //   },
        //   {
        //     name: "Book Office Care",
        //     icon: "office",
        //     path: "/company/office-care",
        //   },

        //   {
        //     name: "Sign Out",
        //     path: "/sign-out",
        //     icon: "sign-out",
        //   },
        // ];
      }
      // else if (this.userType == 4) {
      //   const resp = await API.get(
      //     `${process.env.VUE_APP_API_TRUST}get-menu`,
      //     {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${Auth.getToken()}`,
      //     }
      //   );

      //   let newVar = [];
      //   resp.results.forEach((element) => {
      //     if (this.$i18n.locale == "id") {
      //       newVar = [...newVar, { name: element.name_id, path: element.link, icon: element.icon}]
      //     }else{
      //        newVar = [...newVar, { name: element.name_en, path: element.link, icon: element.icon}]
      //     }
      //   })
      //   this.listMenu = newVar;
      //   // this.listMenu = [
      //   //   {
      //   //     name: "Home",
      //   //     path: "/company",
      //   //     icon: "home",
      //   //   },
      //   //   {
      //   //     name: "Book Office Care",
      //   //     icon: "office",
      //   //     path: "/company/office-care",
      //   //   },
      //   //   {
      //   //     name: this.$t("menu.examination_approve"),
      //   //     icon: "billing",
      //   //     path: "/company/examination-approval",
      //   //   },
      //   //   {
      //   //     name: "Sign Out",
      //   //     path: "/sign-out",
      //   //     icon: "sign-out",
      //   //   },
      //   // ];
      // }
      // else if (this.userType == 5) {
      //   const resp = await API.get(
      //     `${process.env.VUE_APP_API_TRUST}get-menu`,
      //     {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${Auth.getToken()}`,
      //     }
      //   );

      //   let newVar = [];
      //   resp.results.forEach((element) => {
      //     if (this.$i18n.locale == "id") {
      //       newVar = [...newVar, { name: element.name_id, path: element.link, icon: element.icon}]
      //     }else{
      //        newVar = [...newVar, { name: element.name_en, path: element.link, icon: element.icon}]
      //     }
      //   })
      //   this.listMenu = newVar;
      //   // this.listMenu = [
      //   //   {
      //   //     name: "Home",
      //   //     path: "/company",
      //   //     icon: "home",
      //   //   },
      //   //   {
      //   //     name: "Book Office Care",
      //   //     icon: "office",
      //   //     path: "/company/office-care",
      //   //   },
      //   //   {
      //   //     name: this.$t("menu.examination_approve"),
      //   //     icon: "billing",
      //   //     path: "/company/examination-approval",
      //   //   },
      //   //   {
      //   //     name: "Sign Out",
      //   //     path: "/sign-out",
      //   //     icon: "sign-out",
      //   //   },
      //   // ];
      // }
      else if (this.userType == 6) {
        this.listMenu = [
          {
            name: "Home",
            path: "/medical-center",
            icon: "home",
          },
          // {
          //   name: "Summary Reports",
          //   path: "/doctor/summary-report/referral",
          //   icon: "summary-reports",
          // },
          // {
          //   name: "Referral",
          //   path: "/doctor/referral/confirmation",
          //   icon: "user-plus",
          // },
          {
            name: "Sign Out",
            path: "/sign-out",
            icon: "sign-out",
          },
        ];
        // this.settingMenu = {
        //   name: "Setting",
        //   path: "/doctor/setting/profile",
        //   icon: "setting",
        // };
      } else {
        this.listMenu = null;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.name {
  margin-left: -80%;
  font-size: 14px;
  margin-top: 2px;
  color: #00a4b3;
  p {
    text-transform: capitalize;
  }
}

.list-menu {
  margin-left: 15px;
}

.list-item {
  margin-top: -10px;
}

.dialog {
  z-index: 1000;
}

a {
  color: #00a4b3;
  text-decoration: none;
}

.text-title {
  font-size: 16px !important;
  word-break: break-word;
  line-height: 16px;
  font-weight: normal !important;
}
.text-card {
  line-height: 15px;
  color: rgb(68, 67, 67);
  font-size: 12px;
}
.notif-scroll {
  max-height: 500px;

  overflow-y: scroll;
}
.unread > .inside {
  background: rgba(255, 255, 159, 0.3) !important;
}

.unread .text-title {
  font-weight: bold !important;
}
</style>

<template>
  <div class="cart">
    <v-badge
      color="pink"
      :content="$store.state.cart.length"
      :value="$store.state.cart.length"
      class="badge"
    >
      <!-- <IconCart @click="toggleCart" /> -->
      <IconCart @click="toggleCart" color="#00A4B3" />
    </v-badge>
    <!-- detail -->
    <Popup v-if="$store.state.menuActive == 'cart'">
      <cart-detail />
    </Popup>
  </div>
</template>

<script>
//import IconCart from "../elements/IconCart.vue";
import IconCart from "../elements/icv2_test_cart_blue.vue";
//import IconCart from "@/components/elements/Icons.vue";
import Popup from "./Popup.vue";
import CartDetail from "./CartDetail.vue";
// import API from "../../service/api_service";
// import Auth from "../../service/auth_service";
export default {
  components: { IconCart, Popup, CartDetail },
  methods: {
    toggleCart() {
      this.$store.commit("toggleMenuActive", "cart");
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.cart {
  cursor: pointer;
  position: relative;
  display: flex;
  // width: 60px;
  // height: 60px;
  justify-content: center;
  align-items: center;
  background: white;
  border-radius: 12px;
}
</style>

<style lang="scss">
.cart .v-badge__badge {
  border-radius: 130px !important;
  font-size: 8px !important;
  height: 16px !important;
  min-width: 3px !important;
}
</style>

<template>
  <div v-click-outside="closeMenu">
    <div class="header">
      <h4>
        {{
          $store.state.cart.length !== 0 &&
          $store.state.cart[0].plant != undefined
            ? $store.state.cart.length + " ITEMS"
            : $t("page.cart.cart")
        }}
      </h4>
      <localized-link :to="`/patient/cart?date=${$store.state.date}`">
        <h4 class="hover-view">{{ $t("view_all") }}</h4>
      </localized-link>
    </div>
    <ul>
      <template v-if="!this.dealPackage">
        <template
          v-if="
            $store.state.cart.length > 0 &&
            $store.state.cart[0].plant != undefined
          "
        >
          <template v-if="$store.state.cart.product !== null">
            <li
              v-for="(cart, index) of $store.state.cart"
              :key="index"
              :class="{
                crs: cart.product !== null && cart.product.prices.length === 0,
              }"
            >
              <!-- {{ cart }} -->
              <!-- <localized-link
              :to="
                `/dashboard/book-appointment-list-detail?branch=${cart.plant.id}&&product=${cart.product.id}`
              "
            > -->
              <!-- <a :href="`/dashboard/book-appointment-list-detail?branch=${cart.plant.id}&&product=${cart.product.id}`"> -->

              <template
                v-if="cart.product !== null && cart.product.prices.length === 0"
              >
                <div
                  class="item"
                  :class="{
                    red:
                      cart.product !== null && cart.product.prices.length === 0,
                  }"
                >
                  <p>
                    {{
                      $i18n.locale == "id"
                        ? cart.product.name
                        : cart.product[`name_${$i18n.locale}`]
                    }}
                  </p>
                </div>
              </template>
              <template v-else>
                <localized-link
                  v-if="cart.product !== null"
                  :to="`/patient/test/${cart.product.id}?branch=${cart.plant.id}`"
                >
                  <div class="item">
                    <p>
                      {{
                        $i18n.locale == "id"
                          ? cart.product.name
                          : cart.product[`name_${$i18n.locale}`]
                      }}
                    </p>
                  </div>
                </localized-link>
              </template>
              <!-- <p class="price" v-if="cart.discount_price === ''">
            {{ cart.real_price }}
          </p>
          <p class="price" v-if="cart.discount_price !== ''">
            {{ cart.discount_price }}
          </p> -->
              <div v-if="cart.from_package == false" class="item-del">
                <localized-link
                  v-if="cart.product !== null"
                  :to="`/patient/test/${cart.product.id}?branch=${cart.plant.id}`"
                >
                  <!--
              <p class="price" >
                IDR
                {{
                  cart.product.prices.length !== 0
                    ? formater(cart.product.prices[0].amount)
                    : "0"
                }}.00
              </p>
              -->
                  <p
                    class="price"
                    v-if="
                      cart.product !== null && cart.product.prices.length !== 0
                    "
                  >
                    IDR
                    {{
                      formater(
                        cart.product.prices[
                          cart.product.prices.findIndex(
                            res => res.payment_method_id == 1
                          )
                        ].amount
                      )
                    }}.00
                  </p>

                  <div v-else>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon dense v-bind="attrs" v-on="on"
                          >mdi-help-circle-outline</v-icon
                        >
                      </template>
                      <span>{{ $t("page.cart.this_test_not_avail") }}</span>
                    </v-tooltip>
                  </div>
                </localized-link>
                <p
                  class="remove"
                  @click="
                    removeCart(null, null, cart.product.id, cart.plant.id)
                  "
                >
                  X
                </p>
              </div>
              <div v-else class="item-del">
                <p class="price">Rp 0</p>
                <p
                  class="remove"
                  @click="
                    removeCart(null, null, cart.product.id, cart.plant.id)
                  "
                >
                  X
                </p>
              </div>
            </li>
            <div class="item-sub">
              <p>{{ $t("page.cart.subtotal") }} :</p>
              <p>IDR {{ formater(subTotal(this.$store.state.cart)) }}.00</p>
            </div>
            <div class="footer-class">
              <localized-link
                :to="`/patient/cart?date=${this.$store.state.date}`"
              >
                <v-btn rounded class="btn-detail">
                  {{ $t("page.cart.checkout") }}
                </v-btn>
              </localized-link>
            </div>
          </template>
        </template>
        <template v-else>
          <li class="text-center pt-3 pb-3">
            {{ $t("page.cart.empty_cart") }}
          </li>
        </template>
      </template>
      <template v-else>
        <template
          v-if="
            $store.state.cart.length > 0 &&
            $store.state.cart[0].plant != undefined
          "
        >
          <template v-if="$store.state.cart.package !== null">
            <li
              v-for="(cart, index) of $store.state.cart"
              :key="index"
              :class="{
                crs: cart.package !== null && cart.package.prices.length === 0,
              }"
            >
              <template
                v-if="cart.package !== null && cart.package.prices.length === 0"
              >
                <div
                  class="item"
                  :class="{
                    red: cart.package !== null && !cart.package.available,
                  }"
                >
                  <p>
                    {{ cart.package.name }}
                    <!-- {{
                    $i18n.locale == "id"
                      ? cart.package.name
                      : cart.package[`name_${$i18n.locale}`]
                  }} -->
                  </p>
                </div>
              </template>
              <template v-else>
                <localized-link
                  v-if="cart.package !== null"
                  :to="`/patient/test/${cart.package.id}?branch=${cart.plant.id}`"
                  :class="{
                    red: cart.package !== null && !cart.package.available,
                  }"
                >
                  <div class="item">
                    <p>
                      {{ cart.package.name }}
                    </p>
                  </div>
                </localized-link>
              </template>
              <div v-if="cart.from_package == false" class="item-del">
                <localized-link
                  v-if="cart.package !== null"
                  :to="`/patient/test/${cart.package.id}?branch=${cart.plant.id}`"
                >
                  <p
                    class="price"
                    v-if="
                      cart.deal !== null &&
                      cart.package.prices.length !== 0 &&
                      cart.package.available
                    "
                  >
                    IDR
                    {{ formater(cart.package.prices[0].amount_after_deal) }}.00
                  </p>
                  <p
                    class="price"
                    v-else-if="
                      cart.deal === null &&
                      cart.package.prices.length !== 0 &&
                      cart.package.available
                    "
                  >
                    IDR
                    {{ formater(cart.package.prices[0].amount_per_unit) }}.00
                  </p>
                  <div v-else>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon dense v-bind="attrs" v-on="on"
                          >mdi-help-circle-outline</v-icon
                        >
                      </template>
                      <span>{{ $t("page.cart.this_test_not_avail") }}</span>
                    </v-tooltip>
                  </div>
                </localized-link>
                <p
                  v-if="cart.deal !== null"
                  class="remove"
                  @click="
                    removeCart(
                      cart.deal.id,
                      cart.package.id,
                      null,
                      cart.plant.id
                    )
                  "
                >
                  X
                </p>
                <p
                  v-else
                  class="remove"
                  @click="
                    removeCart(null, cart.package.id, null, cart.plant.id)
                  "
                >
                  X
                </p>
              </div>
              <div v-else class="item-del">
                <p class="price">Rp 0</p>
                <p
                  v-if="cart.deal !== null"
                  class="remove"
                  @click="
                    removeCart(
                      cart.deal.id,
                      cart.package.id,
                      null,
                      cart.plant.id
                    )
                  "
                >
                  X
                </p>
                <p
                  v-else
                  class="remove"
                  @click="
                    removeCart(null, cart.package.id, null, cart.plant.id)
                  "
                >
                  X
                </p>
              </div>
            </li>
            <div class="item-sub">
              <p>{{ $t("page.cart.subtotal") }} :</p>
              <p>IDR {{ formater(subTotalDeal(this.$store.state.cart)) }}.00</p>
            </div>
            <div class="footer-class">
              <localized-link :to="`/patient/cart?date=${selectedDate}`">
                <v-btn rounded class="btn-detail">
                  {{ $t("page.cart.checkout") }}
                </v-btn>
              </localized-link>
            </div>
          </template>
        </template>
        <template v-else>
          <li class="text-center pt-3 pb-3">
            {{ $t("page.cart.empty_cart") }}
          </li>
        </template>
      </template>
    </ul>
  </div>
</template>

<script>
import Auth from "@/service/auth_service";
import API from "@/service/api_service.js";
import moment from "moment";
export default {
  data: () => ({
    paramBranchSLug: "",
    //  mypackage: false,
    dealPackage: false,
  }),
  mounted() {
    this.paramBranchSLug = this.$route.params.slug;
    const findDealPackage = this.$store.state.cart.find(
      res => res.deal !== null || res.package !== null
    );
    if (typeof findDealPackage !== "undefined") {
      this.dealPackage = true;
    }
  },
  computed: {
    selectedDate() {
      return moment().format("YYYY-MM-DD");
    },
  },
  methods: {
    toast(status, messages) {
      this.$swal
        .mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: toast => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        })
        .fire({
          icon: status,
          title: messages,
        });
    },
    formater(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    closeMenu() {
      this.$store.commit("toggleMenuActive", "");
    },
    subTotal(sum) {
      const sumFilter = sum.filter(item => {
        return item.from_package == false && item.product !== null;
      });

      return sumFilter.reduce((acc, ele) => {
        return (
          acc +
          parseInt(
            ele.product.prices[
              ele.product.prices.findIndex(res => res.payment_method_id == 1)
            ].amount
          )
        );
      }, 0);
      //    return sum.reduce((acc, ele) => {
      //      return acc + parseInt(ele.product.prices[0].amount);
      //  }, 0);
    },
    subTotalDeal(sum) {
      // const sumFilter = sum.filter(item => {
      //   return item.from_package == false && item.package !== null && item.deal !== null;
      // });

      const sumFilter = sum.map(el => {
        var abc = "";
        if (el.deal != null) {
          abc = el.package.prices[0].amount_after_deal;
        } else {
          abc = el.package.prices[0].amount_per_unit;
        }
        return abc;
      });

      return sumFilter.reduce((acc, ele) => {
        return acc + parseInt(ele);
      }, 0);
    },
    async updateCartApi() {
      await this.$store.dispatch("fetchCartData");
    },
    removeCart(deal_id, package_id, product_id, branch) {
      var dataReady = [];
      if (deal_id != null) {
        dataReady = [
          {
            plant_id: branch,
            package_id: package_id,
            deal_id: deal_id,
            from_package: false,
            product_id: null,
            qty: 0,
          },
        ];
      } else {
        if (package_id != null) {
          dataReady = [
            {
              plant_id: branch,
              package_id: package_id,
              deal_id: null,
              from_package: false,
              product_id: null,
              qty: 0,
            },
          ];
        } else {
          dataReady = [
            {
              plant_id: branch,
              package_id: null,
              deal_id: null,
              from_package: false,
              product_id: product_id,
              qty: 0,
            },
          ];
        }
      }

      API.post(
        `${process.env.VUE_APP_API_TRUST}cart/store`,
        // ?lang=${this.$i18n.locale}
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${Auth.getToken()}`,
        },
        dataReady
      ).then(res => {
        if (res.statusCode == 200) {
          this.updateCartApi();
        } else if (res.statusCode == 403) {
          Object.keys(res.errors).forEach(element => {
            this.toast(
              "error",
              typeof res.errors[element] == "object"
                ? res.errors[element][0]
                : res.errors[element]
            );
          });
        } else {
          this.$swal({
            icon: "error",
            text: res.message,
            //text: this.$t("error_try_again_latter"),
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/custom.scss";
.header {
  cursor: default;
  display: flex;
  width: 250px;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  padding: 18px;
  border-bottom: 1px solid $gray_3;
  h4 {
    //color: $blue_1;
    color: $gray_1;
  }
  a {
    text-decoration: none;
    color: $blue_2;
  }
}
ul {
  list-style: none;
  padding: 0 12px 12px 12px;
  color: $gray_1;
  li {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid $gray_3;
    .item {
      display: flex;
      padding-top: 10px 0 0 0;
      text-align: left;
      margin-left: -5px;
      font-weight: 600;
      margin-top: 5px;
      p {
        margin-bottom: 0;
        margin-left: 10px;
        width: 100%;
      }
    }
    .price {
      text-align: right;
      margin-bottom: 8px;
      text-align: left;
      margin-left: 5px;
      font-size: 12px;
    }
  }
}
a {
  text-decoration: none;
  //color: #858585 !important;
  color: $gray_1 !important;
}
.hover-view {
  //color:#858585 !important;
  color: $gray_1 !important;
}
.hover-view:hover {
  text-decoration: underline !important;
}
.item-sub {
  display: flex;
  padding: 20px 0;
  font-weight: 600;
  color: $gray_1;
  justify-content: space-between;
  font-size: 14px;
}
.footer-class {
  text-align: center;
  margin-top: -15px;
  margin-bottom: 20px;
}
.btn-detail {
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
  border-radius: 4px;
  background-color: #00a4b3 !important;
  color: #fff;
  font-size: 12px;
  height: 35px !important;
  min-width: 150px !important;
  font-weight: 500;
  text-align: center;
}
.item-del {
  display: flex;
  justify-content: space-between;
}
.remove {
  margin-top: -10px;
  cursor: pointer;
}
.red {
  color: red !important;
  background: none !important;
}
.crs {
  cursor: default !important;
}
</style>
